<template>
  <div :class="`match-card ${match.map}`">
    <router-link :to="{ name: 'MatchSingle', params: { id: match.id }}">
      <div class="round-title">
        <h6>
          <div class="team-column" v-if="match && match.team_a && match.team_b">
            <span v-if="match.team_a" class="team">{{match.team_a.name}}</span>x
            <span v-if="match.team_b" class="team">{{match.team_b.name}}</span>
          </div>
          <p v-else v-html="match.name || 'partida sem nome' "></p>
        </h6>
      </div>
      <div class="round-details" v-if="match.winner_string">vencedor {{ match.winner_string }}</div>
      <div class="round-details">partida completa</div>

      <div class="round-badges">
        <b-badge size="sm" variant="info">{{match.map}}</b-badge>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MiniMatch",
  props: {
    match: Object
  },
  methods: {
  }
};
</script>

<style lang="sass" scoped>
.match-card
  border: 1px solid #d1d1d1
  margin-bottom: 10px
  padding: 6px 10px
  border-radius: 4px
  background: #f9f9f9
  transition: all .1s
  position: relative

  &:hover
    transform: scale(1.05)

  .round-title
    h5
      margin: 0
    h6
      margin: 0
    .team-column
      span
        margin-right: 5px
  
  .round-details
    font-size: 12px
  
  .round-badges
    position: absolute
    top: 3px
    right: 5px
    .badge
      margin-left: 5px
  
  .round-stats
    position: absolute
    top: 30px
    right: 5px
    p.stat
      display: inline-block
      margin-left: 10px
  
  a
    color: #aaa
    &:hover
      text-decoration: none
      color: white
  p
    margin: 0
</style>
