<template>
  <div class="container">
    <div class="collection-title page-title">
      <div class="row">
        <div class="col col-md-9">
          <h1 v-if="collection.name">{{ collection.name }}</h1>
          <p v-if="collection.description">{{ collection.description }}</p>
        </div>
        <div class="col col-md-3">
          <small>{{ $t('COLLECTIONS.created_by') }}</small>
          <div class="user-avatar">
            <router-link :to="{ name: 'UserSingle', params: { id: collection.user.id }}">
              <img :src="collection.user.avatar.url">
            </router-link>
          </div>
          <h3>{{ collection.user.display_name }}</h3>
        </div>
      </div>
    </div>
    <div class="stats-table table-responsive">
      <table class="table table-sm is-hoverable">
        <thead>
          <tr>
            <!-- <th>Match</th>
            <th>Description</th>-->
            <!-- <th>Tags</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in collection.items" :key="item.id">
            <td>
              <div v-if="item.favorable_type == 'Match'">
                <MiniMatch :match="item.favorable"></MiniMatch>
              </div>

              <div v-if="item.favorable_type == 'MatchRound'">
                <MiniRound :round="item.favorable"></MiniRound>
              </div>
            </td>
            <td class="collection-item-info">
              <p v-if="item.name">{{ item.name }}</p>
              <p v-if="item.description">{{ item.description }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import MiniRound from "@/components/MiniRound";
import MiniMatch from "@/components/MiniMatch";
export default {
  name: "CollectionSingle",
  data() {
    return {
      collection: { items: [] },
      name: ""
    };
  },
  computed: {
  },
  methods: {
    initialize() {
      this.$http
        .get(`collections/${this.$route.params.id}.json`)
        .then(response => {
          this.collection = response.data;
        });
    }
  },
  mounted() {
    this.initialize();
  },
  components: {
    MiniRound,
    MiniMatch
  }
};
// Header
</script>

<style lang="sass" scoped>
.collection-title
  margin: 20px 0
.collection-item-info
  p
    margin: 0
.match-card,
.round-card
  margin-bottom: 0
.user-avatar
  width: 48px
  height: 48px
  min-width: 48px
  display: block
  overflow: hidden
  // padding: 5px
  margin: 5px
  margin-right: 15px
  img
    width: 100%
    height: auto
    border-radius: 250%
    // min-width: 48px
a
  color: #666
  &:hover
    color: #222
</style>
